import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: () =>
            import ('@/views/landing.vue')
    },
    {
        path: '/our-story',
        name: 'Our Story',
        component: () =>
            import ('@/views/our-story.vue')
    },
    {
        path: '/case-studies',
        name: 'Case Studies',
        component: () =>
            import ('@/views/case-studies.vue')
    },
    {
        path: '/case-studies/alex',
        name: 'Case Studies',
        component: () =>
            import ('@/views/case-studies/alex.vue')
    },
    {
        path: '/case-studies/jane',
        name: 'Case Studies',
        component: () =>
            import ('@/views/case-studies/jane.vue')
    },
    {
        path: '/case-studies/sikina',
        name: 'Case Studies',
        component: () =>
            import ('@/views/case-studies/sikina.vue')
    },
    {
        path: '/case-studies/cecilia',
        name: 'Case Studies',
        component: () =>
            import ('@/views/case-studies/cecilia.vue')
    },
    {
        path: '/blog',
        name: 'Blog',
        component: () =>
            import ('@/views/blog/blog.vue')
    },
    {
        path: '/blog/:articleID',
        name: 'Blog',
        component: () =>
            import ('@/views/blog/blog-article.vue')
    },
    {
        path: '/press',
        name: 'Press',
        component: () =>
            import ('@/views/press.vue')
    },
    {
        path: '/pricing',
        name: 'Pricing',
        component: () =>
            import ('@/views/pricing.vue')
    },
    {
        path: '/jobs',
        name: 'Jobs',
        component: () =>
            import ('@/views/jobs.vue')
    },
    {
        path: '/terms',
        name: 'Terms',
        component: () =>
            import ('@/views/terms.vue')
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: () =>
            import ('@/views/privacy.vue')
    },
    {
        path: '/fair-chance',
        name: 'Fair chance',
        component: () =>
            import ('@/views/fair-chance.vue')
    },
    {
        path: '/cookies',
        name: 'Cookies',
        component: () =>
            import ('@/views/cookies.vue')
    }, {
        path: '/servicers',
        name: 'Servicers onboarding',
        component: () =>
            import ('@/views/servicers-onboarding.vue')
    }, {
        path: '/do-not-sell',
        name: 'Do Not Sell My Personal Information',
        component: () =>
            import ('@/views/do-not-sell.vue')
    }, {
      path: '/data-retention',
      name: 'Data Retention',
      component: () =>
          import ('@/views/data-retention.vue')
  }, {
        path: '/investors',
        name: 'Investors',
        meta: {
          requiresAuth: true
        },
        component: () =>
            import ('@/views/investors.vue')
    }, 
  {
    path: '/invite/:inviteCode',
    name: 'Invite Code',
    component: () =>
        import ('@/views/Redirects/Invite.vue')
  },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router