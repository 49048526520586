import Vue from 'vue';
import App from './App.vue';
import './index.css';
import router from './router';

Vue.config.productionTip = false;

if (process.env.NODE_ENV === "production") {
    window.api = "https://homeplan-api.herokuapp.com/v1";
} else {
    window.api = "http://localhost:4000/v1";
}

// Toast notifications
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
const options = {
    draggable: true,
    draggablePercent: 0.6,
    pauseOnHover: true,
    timeout: 3000,
    position: POSITION.BOTTOM_CENTER
};

Vue.use(Toast, options);

// Google Analytics
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
    config: { id: "G-TPJ3WWV4QR" }
}, router);


// Cookies
import VueCookies from 'vue-cookies';

Vue.use(VueCookies);
Vue.$cookies.config(Infinity);


router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0)
    next()
})

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')